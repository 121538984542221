import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {Tournament} from "./tournamentsSlice";
import {resetStore} from "../../app/rootReducer";

interface SelectedTournamentState {
    selectedTournament: Tournament | null;
}

const initialState: SelectedTournamentState = {
    selectedTournament: null,
};

const selectedTournamentSlice  = createSlice({
    name: 'tournaments',
    initialState,
    reducers: {
        setSelectedTournament: (state, action: PayloadAction<Tournament>) => {
            state.selectedTournament = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(resetStore, () => initialState) // Resetuje stan do wartości początkowych
    },
});

export const { setSelectedTournament } = selectedTournamentSlice.actions;

export const selectSelectedTournament = (state: RootState) => state.selectedTournament.selectedTournament;

export default selectedTournamentSlice.reducer;
