import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../../app/store';
import {resetStore} from "../../app/rootReducer";

// Definicja typu turnieju
export interface Tournament {
    id: number;
    name: string;
    url: string;
    state: string;
}

// Stan początkowy
interface TournamentState {
    tournaments: Tournament[];
    loading: boolean;
    error: string | null;
}

const initialState: TournamentState = {
    tournaments: [],
    loading: false,
    error: null,
};

// Asynchroniczna akcja do pobierania turniejów z użyciem axios
export const fetchTournaments = createAsyncThunk('tournaments/fetchTournaments', async () => {
    try {
        const response = await axios.get<Tournament[]>('/api/tournaments');
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data || 'Błąd podczas pobierania turniejów');
        } else {
            throw new Error('Wystąpił błąd sieci');
        }
    }
});

// Slice turniejów
const tournamentsSlice = createSlice({
    name: 'tournaments',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTournaments.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchTournaments.fulfilled, (state, action: PayloadAction<Tournament[]>) => {
                state.loading = false;
                state.tournaments = action.payload;
            })
            .addCase(fetchTournaments.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Wystąpił błąd';
            })
            .addCase(resetStore, () => initialState) // Resetuje stan do wartości początkowych
    },
});

export const selectTournaments = (state: RootState) => state.tournaments.tournaments;
export const selectLoading = (state: RootState) => state.tournaments.loading;
export const selectError = (state: RootState) => state.tournaments.error;

export default tournamentsSlice.reducer;
