import { combineReducers } from '@reduxjs/toolkit';
import matchesReducer from '../features/matches/matchesSlice';
import tournamentsReducer from '../features/tournaments/tournamentsSlice';
import selectedTournamentReducer from '../features/tournaments/selectedTournamentSlice';
import { createAction } from '@reduxjs/toolkit';

// Utwórz akcję do resetowania stanu
export const resetStore = createAction('RESET_STORE');

// Połącz reducery
const appReducer = combineReducers({
    matches: matchesReducer,
    tournaments: tournamentsReducer,
    selectedTournament: selectedTournamentReducer,
});

// Zmodyfikuj rootReducer, aby obsługiwał akcję resetującą
const rootReducer = (state: ReturnType<typeof appReducer> | undefined, action: any) => {
    if (action.type === resetStore.type) {
        state = undefined; // Resetuje cały stan aplikacji
    }
    return appReducer(state, action);
};

export default rootReducer;
