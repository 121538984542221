import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchTournaments,
    selectTournaments,
    selectLoading,
    selectError,
    Tournament,
} from '../features/tournaments/tournamentsSlice';
import {
    CircularProgress,
    Container,
    Alert,
    CardContent,
    Card,
    Typography,
    Box,
    Button,
} from '@mui/material';
import { AppDispatch } from '../app/store';
import { useNavigate } from 'react-router-dom';
import { setSelectedTournament } from '../features/tournaments/selectedTournamentSlice';
import { resetStore } from '../app/rootReducer';
import RefreshIcon from '@mui/icons-material/Refresh';
import styles from './TournamentList.module.scss';
const TournamentsList: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const tournaments = useSelector(selectTournaments);
    const loading = useSelector(selectLoading);
    const error = useSelector(selectError);

    useEffect(() => {
        // Reset magazynu Redux
        dispatch(resetStore());
        dispatch(fetchTournaments());
    }, [dispatch]);

    const handleTournamentClick = (tournament: Tournament) => {
        // Zapisz `id` turnieju w magazynie `Redux`
        dispatch(setSelectedTournament(tournament));

        // Przekierowanie do strony z wynikami turnieju
        navigate(`/tournament/${tournament.id}`);
    };

    const getTournamentColor = (state: string) => {
        if (state === 'underway') return 'red';
        if (state === 'awaiting_review') return 'blue';
        if (state === 'pending') return 'gray';
        if (state === 'complete') return 'green';
        return 'black';
    };

    const RefreshButton = () => (
        <Button
            variant="contained"
            endIcon={<RefreshIcon />}
            onClick={() => dispatch(fetchTournaments())}
        >
            Odśwież listę
        </Button>
    );

    const sortedTournaments = tournaments?.slice().sort((a, b) => {
        const stateOrder: Record<string, number> = {
            underway: 0, // w trakcie
            awaiting_review: 1, // sprawdzane
            pending: 2, // oczekujące
            complete: 3, // zakończone
        };

        return (stateOrder[a.state] ?? 4) - (stateOrder[b.state] ?? 4);
    });

    return (
        <Container maxWidth="md" sx={{ marginTop: 4 }}>
            {loading ? <CircularProgress /> : <RefreshButton />}
            {error && <Alert severity="error">Błąd: {error}</Alert>}
            {!loading && !error && sortedTournaments && (
                <>
                    {sortedTournaments.map((tournament) => (
                        <Card
                            sx={{
                                backgroundColor: 'white',
                                '&:hover': {
                                    backgroundColor: '#f5f5f5',
                                },
                                cursor: 'pointer',
                                margin: '10px 0',
                            }}
                            onClick={() => handleTournamentClick(tournament)}
                            key={tournament.id}
                        >
                            <CardContent>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        sx={{ color: 'black' }}
                                    >
                                        {tournament.name}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            color: getTournamentColor(
                                                tournament.state,
                                            ),
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {tournament.state === 'underway' && (
                                            <>
                                                <span
                                                    className={styles.dot}
                                                ></span>
                                                Trwa
                                            </>
                                        )}
                                        {tournament.state === 'complete' &&
                                            'Zakończony'}
                                        {tournament.state ===
                                            'awaiting_review' &&
                                            'Sprawdzanie wyników'}
                                        {tournament.state === 'pending' &&
                                            'Oczekuje na rozpoczęcie'}
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    ))}
                </>
            )}
        </Container>
    );
};

export default TournamentsList;
