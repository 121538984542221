import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../app/store';
import { io } from 'socket.io-client';
import {Participant} from "../../types/types";
import {resetStore} from "../../app/rootReducer";

interface MatchesState {
    matches: Participant[];
    loading: boolean;
}

const initialState: MatchesState = {
    matches: [],
    loading: true,
};

const matchesSlice = createSlice({
    name: 'matches',
    initialState,
    reducers: {
        setMatches(state, action: PayloadAction<Participant[]>) {
            state.matches = action.payload;
            state.loading = false;
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(resetStore, () => initialState) // Resetuje stan do wartości początkowych
    },
});

export const { setMatches, setLoading } = matchesSlice.actions;

// Asynchroniczna akcja do połączenia z `socket.io`
export const fetchMatches = (tournamentId: number | null): AppThunk => (dispatch) => {
    dispatch(setLoading(true));

    const socket = io('/', {
        path: "/api/socket.io",
        query: {
            tournamentId: tournamentId,
        }
    });

    socket.on('group-rankings', (data: Participant[]) => {
        dispatch(setMatches(data));
    });

    return () => {
        socket.disconnect();
    };
};

export default matchesSlice.reducer;
