import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import rootReducer from './rootReducer'; // Zaimportuj rootReducer z odpowiedniej ścieżki

export const store = configureStore({
  reducer: rootReducer, // Użyj rootReducer z obsługą resetowania
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
