import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Container, CircularProgress, IconButton, Box } from '@mui/material';
import { selectSelectedTournament } from '../features/tournaments/selectedTournamentSlice';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

const TournamentPage = () => {
    const selectedTournament = useSelector(selectSelectedTournament);
    const [loading, setLoading] = useState(true);
    const [isFullscreen, setIsFullscreen] = useState(false); // Track fullscreen state
    const containerRef = useRef<HTMLIFrameElement>(null); // Reference to iframe element

    const toggleFullscreen = () => {
        if (!isFullscreen) {
            if (containerRef?.current?.requestFullscreen) {
                containerRef.current.requestFullscreen();
            } else if (
                (containerRef?.current as any)?.webkitRequestFullscreen
            ) {
                // For Safari
                (containerRef.current as any).webkitRequestFullscreen();
            } else if ((containerRef?.current as any)?.mozRequestFullScreen) {
                // For Firefox
                (containerRef.current as any).mozRequestFullScreen();
            } else if ((containerRef?.current as any)?.msRequestFullscreen) {
                // For IE11
                (containerRef.current as any).msRequestFullscreen();
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) {
                // For Safari
                document.webkitExitFullscreen();
            } else if (document.mozCancelFullScreen) {
                // For Firefox
                document.mozCancelFullScreen();
            } else if (document.msExitFullscreen) {
                // For IE11
                document.msExitFullscreen();
            }
        }
    };

    useEffect(() => {
        // Funkcja obsługująca zmiany fullscreen
        const handleFullscreenChange = () => {
            const fullscreenElement =
                document.fullscreenElement ||
                (document as any).webkitFullscreenElement ||
                (document as any).mozFullScreenElement ||
                (document as any).msFullscreenElement;
            setIsFullscreen(!!fullscreenElement);
        };

        // Dodajemy nasłuchiwanie na zmiany fullscreen
        document.addEventListener('fullscreenchange', handleFullscreenChange);
        document.addEventListener(
            'webkitfullscreenchange',
            handleFullscreenChange,
        ); // Safari
        document.addEventListener(
            'mozfullscreenchange',
            handleFullscreenChange,
        ); // Firefox
        document.addEventListener('MSFullscreenChange', handleFullscreenChange); // IE11

        // Sprzątanie: usuwamy event listeners po odmontowaniu komponentu
        return () => {
            document.removeEventListener(
                'fullscreenchange',
                handleFullscreenChange,
            );
            document.removeEventListener(
                'webkitfullscreenchange',
                handleFullscreenChange,
            );
            document.removeEventListener(
                'mozfullscreenchange',
                handleFullscreenChange,
            );
            document.removeEventListener(
                'MSFullscreenChange',
                handleFullscreenChange,
            );
        };
    }, []);

    if (!selectedTournament) {
        return (
            <Container
                maxWidth={false}
                sx={{
                    width: '100%',
                    height: '100vh',
                    padding: 0,
                    margin: 0,
                }}
            >
                <h1>Nie znaleziono turnieju</h1>
            </Container>
        );
    }

    return (
        <>
            {loading && (
                <CircularProgress
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 2,
                    }}
                />
            )}
            <Box
                ref={containerRef}
                sx={{
                    position: 'relative',
                    width: '100%',
                    height: 'calc(100vh - 64px)',
                }}
            >
                <iframe
                    src={`https://challonge.com/pl/${selectedTournament?.url}/module`}
                    width="100%"
                    height="100%"
                    allowTransparency={true}
                    title="Tournament Bracket"
                    onLoad={() => setLoading(false)}
                />
                {!loading && (
                    <IconButton
                        onClick={toggleFullscreen}
                        sx={{
                            position: 'absolute',
                            top: 10,
                            right: 10,
                            zIndex: 2,
                            backgroundColor: 'rgba(255, 255, 255, 0.7)',
                            '&:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                            },
                        }}
                    >
                        {isFullscreen ? (
                            <FullscreenExitIcon />
                        ) : (
                            <FullscreenIcon />
                        )}
                    </IconButton>
                )}
            </Box>
        </>
    );
};

export default TournamentPage;
