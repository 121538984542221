import React, {useEffect, useState} from 'react';
import {Route, Routes, Link, useLocation} from 'react-router-dom';
import {
    AppBar,
    Toolbar,
    Container,
    Typography,
    Box, CssBaseline
} from '@mui/material';
import TournamentsList from "../components/TournamentList";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../app/store";
import {fetchTournaments, selectLoading, selectTournaments, Tournament} from "../features/tournaments/tournamentsSlice";
import {setSelectedTournament} from "../features/tournaments/selectedTournamentSlice";
import TournamentPage from "../components/TournamentPage";

const ListaTurniejow = () => (
    <Container sx={{paddingTop: 4}}>
        <Typography variant="h4" gutterBottom>
            Lista Turniejów
        </Typography>
        <TournamentsList/>
    </Container>
);

interface AppNavProps {
    tournament: Tournament | null;
}

const AppNav: React.FC<AppNavProps> = ({tournament}) => {
    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <AppBar component="nav" position="static">
                <Toolbar>
                    <Typography
                        variant="h6"
                        component={Link}
                        to="/"
                        sx={{textDecoration: 'none', color: 'inherit', my: 2}}
                    >
                        Scoreify
                    </Typography>
                    {tournament && (
                            <Typography variant="h6"
                                        sx={{textAlign: 'center', flexGrow: 1}}>
                                {tournament.name}
                            </Typography>
                    )}
                </Toolbar>
            </AppBar>
        </Box>
    );
};

const AppRoutes: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const tournaments = useSelector(selectTournaments);
    const loading = useSelector(selectLoading);
    const location = useLocation();
    const pathSegments = location.pathname.split('/');
    const idFromPath = pathSegments[2]; // Pobierz `id` z ścieżki `/tournament/:id`

    const [localTournament, setLocalTournament] = useState<Tournament | null>(
        useSelector((state: RootState) => state.selectedTournament?.selectedTournament)
    );
    const isTournamentPage = location.pathname.includes('/tournament/');

    useEffect(() => {
        if (!localTournament && isTournamentPage && !loading) {
            dispatch(fetchTournaments());
        } else if (!isTournamentPage) {
            setLocalTournament(null);
        }
    }, [dispatch, isTournamentPage, localTournament, loading]);

    useEffect(() => {
        if (isTournamentPage && (!localTournament || (localTournament && localTournament.id !== Number(idFromPath))) && tournaments.length > 0) {
            const foundTournament = tournaments.find((t) => t.id === Number(idFromPath)) || null;
            if (foundTournament) {
                dispatch(setSelectedTournament(foundTournament));
                setLocalTournament(foundTournament);
            }
        }
    }, [tournaments, isTournamentPage, idFromPath, dispatch, localTournament]);

    return (
        <>
            <AppNav tournament={localTournament} />
            <Box
                sx={{
                    width: '100%',
                    height: 'calc(100vh - 64px)', // Dopasuj do wysokości AppNav
                    padding: 0,
                    margin: 0,
                    overflow: 'hidden'
                }}
            >
                <Routes>
                    <Route path="/" element={<ListaTurniejow />} />
                    <Route path="/tournament/:id" element={<TournamentPage />} />
                </Routes>
            </Box>
        </>
    );
};

export default AppRoutes;

