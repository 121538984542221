import React from 'react';
import './App.css';
import AppRoutes from './routes/Routes';
import { Provider } from 'react-redux';
import {store} from './app/store';
import { BrowserRouter as Router} from 'react-router-dom';
function App() {
    return (
        <Provider store={store}>
            <div className="App">
                <Router>
                    <AppRoutes />
                </Router>
            </div>
        </Provider>
    );
}

export default App;
